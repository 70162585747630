import React, { useState } from 'react'
import classes from './Inventory.module.css'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import backpack from './data_big_table'

const Inventory = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(1)

  const openList = (index) => {
    setActiveTab(index)
  }

  return (
    <>
      <Helmet>
        <title>{t('inventory')}</title>
      </Helmet>
      <div className={classes.wrapper}>
        <h2 className={classes.title}> Ուսապարկի պարունակություն </h2>
        <div className={classes.columns_wrapper}>
          {backpack.map((items) => {
            return (
              <div key={items.id} className={classes.column}>
                <div className={classes.top}>
                  <img src={items.img} alt='icon' />
                  <h3>{t(items.name)}</h3>
                </div>

                {items.general_terms.map((item) => {
                  return (
                    <Link key={item.name} to={'/inventory' + item.linkTo}>
                      <div className={classes.item}>
                        <p>{t(item.name)}</p>
                      </div>
                    </Link>
                  )
                })}
              </div>
            )
          })}
        </div>

        <div className={classes.table}>
          <div className={classes.top_row}>
            {backpack.map((item, index) => (
              <div
                key={item.id}
                className={activeTab === index + 1 ? classes.tab + ' ' + classes.active : classes.tab}
                onClick={() => openList(index + 1)}
              >
                <img src={item.img} alt='img' />
              </div>
            ))}
          </div>
          {activeTab === 1 && (
            <div className={classes.list}>
              {backpack[0].general_terms.map((item) => {
                return (
                  <Link key={item.name} to={'/inventory' + item.linkTo}>
                    {t(item.name)}
                  </Link>
                )
              })}
            </div>
          )}

          {activeTab === 2 && (
            <div className={classes.list}>
              {backpack[1].general_terms.map((item) => {
                return (
                  <Link key={item.name} to={'/inventory' + item.linkTo}>
                    {t(item.name)}
                  </Link>
                )
              })}
            </div>
          )}
          {activeTab === 3 && (
            <div className={classes.list}>
              {backpack[2].general_terms.map((item) => {
                return (
                  <Link key={item.name} to={'/inventory' + item.linkTo}>
                    {t(item.name)}
                  </Link>
                )
              })}
            </div>
          )}
          {activeTab === 4 && (
            <div className={classes.list}>
              {backpack[3].general_terms.map((item) => {
                return (
                  <Link key={item.name} to={'/inventory' + item.linkTo}>
                    {t(item.name)}
                  </Link>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Inventory
