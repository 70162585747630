import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classes from './cta.module.css';
import Button from '../UI/Button/Button';

const StickyCTAButton = () => {
  const location = useLocation();

  const { t } = useTranslation()

  if (location.pathname.match(/become-member/)){
    return null;
  }

  return (
    <div className={classes.stickyCtaContainer}>
      <Link to='become-member'>
        <Button text={t('become_member')} />
      </Link>
    </div>
  );
};

export default StickyCTAButton;