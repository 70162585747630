import Modal from '../UI/Modal/Modal'
import classes from './GalleryPhotoModal.module.css'

const GalleryPhotoModal = (props) => {
  return (
    <Modal onClose={props.onClose} styleName='gallery'>
      <img src={props.image} alt={props.text} className={classes.img} />
    </Modal>
  )
}

export default GalleryPhotoModal
