import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import GalleryPhotoModal from '../GalleryPhotoModal/GalleryPhotoModal'
import Container from '../UI/Container/Container'
import Shadow from '../UI/Shadow/Shadow'
import Button from '../UI/Button/Button'
import classes from './Gallery.module.css'
import Header from '../UI/Header/Header'

const Gallery = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [modalIsShown, setModalIsShown] = useState(false)
  const [selectedPhoto, setSelectedPhoto] = useState(false)

  let photos = [
    {
      id: 1,
      image: '/images/photos/gallery/gallery-1.png',
      description: t('gallery-popUp1'),
      date: '26.02.2020',
    },
    {
      id: 2,
      image: '/images/photos/gallery/gallery-2.jpg',
      description: t('gallery-2-popUp'),
      date: '',
    },
    {
      id: 4,
      image: '/images/photos/gallery/gallery-4.png',
      description: t('gallery-4-popUp'),
      date: '',
    },
    {
      id: 3,
      image: '/images/photos/gallery/gallery-3.png',
      description: t('gallery-popUp2'),
      date: '26.02.2020',
    },
    {
      id: 5,
      image: '/images/photos/gallery/gallery-5.png',
      description: t('gallery-5-popUp'),
      date: '',
    },
    {
      id: 6,
      image: '/images/photos/gallery/gallery-6.jpg',
      description: t('gallery-6-popUp'),
      date: '',
    },
  ]

  if (props.count) {
    photos = photos.slice(0, props.count)
  }

  const showModalHandler = (photoId) => {
    setSelectedPhoto(photos.find((photo) => photo.id === photoId))
    setModalIsShown(true)
  }

  const hideModalHandler = () => {
    setModalIsShown(false)
  }

  const onClick = () => {
    navigate('/gallery')
  }

  const helmet = !props.count && (
    <Helmet>
      <title>{t('gallery')}</title>
    </Helmet>
  )

  return (
    <>
      {helmet}
      <Container>
        <div className={classes.wrapper}>
          {modalIsShown && (
            <GalleryPhotoModal
              onClose={hideModalHandler}
              text={selectedPhoto.description}
              image={selectedPhoto.image}
            />
          )}
          <div className={classes.header}>
            <Header text={t('gallery')} />
          </div>
          <div className={classes.portraitGroup}>
            {photos.map((photo) => (
              <Shadow
                key={photo.id}
                id={photo.id}
                src={photo.image}
                hasPopUp={true}
                text={photo.description}
                date={photo.date}
                clickable={true}
                onShowModal={showModalHandler}
              />
            ))}
          </div>
          {props.count && (
            <div className={classes.button}>
              <Button text={t('more')} onClick={onClick} />
            </div>
          )}
        </div>
      </Container>
    </>
  )
}

export default Gallery
