const backpack = [
  {
    id: 'medicine',
    name: 'medicine_title',
    general_terms: [
      {
        name: 'medicine1',
        linkTo: '/medicine1',
      },
      {
        name: 'medicine2',
        linkTo: '/medicine2',
      },
      {
        name: 'medicine3',
        linkTo: '/medicine3',
      },
    ],
    img: 'images/photos/backpack/medicine.svg',
  },
  {
    id: 'food',

    name: 'food_title',
    general_terms: [
      {
        name: 'food1',
        linkTo: '/food1',
      },
      {
        name: 'food2',
        linkTo: '/food2',
      },
      {
        name: 'food3',
        linkTo: '/food3',
      },
    ],
    img: 'images/photos/backpack/food.svg',
  },
  {
    id: 'weather',
    name: 'weather_title',
    general_terms: [
      {
        name: 'weather1',
        linkTo: '/weather1',
      },
      {
        name: 'weather2',
        linkTo: '/weather2',
      },
      {
        name: 'weather3',
        linkTo: '/weather3',
      },
      {
        name: 'weather4',
        linkTo: '/weather4',
      },
      {
        name: 'weather5',
        linkTo: '/weather5',
      },
    ],
    img: 'images/photos/backpack/protection.svg',
  },
  {
    id: 'mission',
    name: 'mission_title',
    general_terms: [
      {
        name: 'mission1',
        linkTo: '/mission1',
      },
      {
        name: 'mission2',
        linkTo: '/mission2',
      },
      {
        name: 'mission3',
        linkTo: '/mission3',
      },
      {
        name: 'mission4',
        linkTo: '/mission4',
      },
      {
        name: 'mission5',
        linkTo: '/mission5',
      },
      {
        name: 'mission6',
        linkTo: '/mission6',
      },
    ],
    img: 'images/photos/backpack/target.svg',
  },
]

export default backpack
