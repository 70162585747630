import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form } from 'formik'
import DonationRuleModal from '../DonationRuleModal/DonationRuleModal'
import Button from '../UI/Button/Button'
import classes from './DonationForm.module.css'

const DonationForm = () => {
  const [isActive, setIsActive] = useState('donate_azatazen')
  const [donationType, setDonationType] = useState(1)
  const [selectedAmount, setSelectedAmount] = useState('10000')
  const [modalIsShown, setModalIsShown] = useState(false)
  const { t } = useTranslation()
  const validateEmail = (value) => {
    let error
    if (!value) {
      error = t('required')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = t('invalid')
    }
    return error
  }
  const minValues = {
    AMD: 1000,
    USD: 5,
    EUR: 5,
  }

  const validateField = (value) => {
    let error
    if (!value) {
      error = t('required')
    }
    return error
  }

  const onButtonClickHandler = (type, setFunction) => {
    setFunction(type)
  }

  const showModalHandler = () => {
    setModalIsShown(true)
  }

  const hideModalHandler = () => {
    setModalIsShown(false)
  }
  return (
    <div className={classes.form_column}>
      {modalIsShown && <DonationRuleModal onClose={hideModalHandler} />}

      <h2>{t(`${isActive}`)}</h2>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          comment: '',
          amount: '',
          currency: 'AMD',
          checked: false,
          donationType: '1',
        }}
        onSubmit={(values) => {
          if (selectedAmount !== 'other') {
            values.amount = selectedAmount
          } else if (values.amount === '') {
            values.amount = minValues[values.currency]
          }
          values.comment = `${isActive} ${values.comment}`
          values.donationType = donationType;
          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
          }
          fetch('https://api-v2.azatazen.am/make-donation', requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                alert('Տեղի ունեցավ սխալ');
              } else if (data.result) {
                window.location.href = data.result.formUrl
              }
            }).catch((error) => {
              alert('Տեղի ունեցավ սխալ!!!');
            });
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, values, validateForm }) => (
          <Form className={classes.form} autoComplete='off'>
            <div className={classes.buttons_wrapper}>
              <div
                onClick={() => onButtonClickHandler('donate_azatazen', setIsActive)}
                className={isActive === 'donate_azatazen' ? classes.btn_active : classes.btn_regular}
              >
                {t('donation')}
              </div>
              <div
                onClick={() => onButtonClickHandler('membership_fee', setIsActive)}
                className={isActive === 'membership_fee' ? classes.btn_active : classes.btn_regular}
              >
                {t('membership')}
              </div>
            </div>

            <div className={classes.buttons_wrapper}>
              <div
                onClick={() => onButtonClickHandler(1, setDonationType)}
                className={donationType === 1 ? classes.btn_active : classes.btn_regular}
              >
                {t('monthly')}
              </div>
              <div
                onClick={() => onButtonClickHandler(2, setDonationType)}
                className={donationType === 2 ? classes.btn_active : classes.btn_regular}
              >
                {t('one_time')}
              </div>
            </div>

            <div className={classes.buttons_wrapper + ' ' + classes.four_buttons}>
              <div
                onClick={() => onButtonClickHandler('10000', setSelectedAmount)}
                className={selectedAmount === '10000' ? classes.btn_active : classes.btn_regular}
              >
                10,000 {t('amd')}
              </div>
              <div
                onClick={() => onButtonClickHandler('20000', setSelectedAmount)}
                className={selectedAmount === '20000' ? classes.btn_active : classes.btn_regular}
              >
                20,000 {t('amd')}
              </div>
              <div
                onClick={() => onButtonClickHandler('30000', setSelectedAmount)}
                className={selectedAmount === '30000' ? classes.btn_active : classes.btn_regular}
              >
                30,000 {t('amd')}
              </div>
              <div
                onClick={() => onButtonClickHandler('other', setSelectedAmount)}
                className={selectedAmount === 'other' ? classes.btn_active : classes.btn_regular}
              >
                {t('other')}
              </div>
            </div>
            {selectedAmount === 'other' && (
              <div className={classes.selected_form}>
                <Field
                  className={classes.selected_input}
                  type='number'
                  placeholder={minValues[values.currency]}
                  min={minValues[values.currency]}
                  name='amount'
                  autoComplete='off'
                />
                <Field as='select' id='currency' name='currency' className={classes.AMD}>
                  <option value='AMD'>{t('amd')}</option>
                  <option value='USD'>USD</option>
                  <option value='EUR'>EUR</option>
                </Field>
              </div>
            )}

            <label htmlFor='name'>{t('first_name')} </label>
            <Field
              className={errors.firstName ? classes.form_input + ' ' + classes.redBorder : classes.form_input}
              id='firstName'
              type='text'
              name='firstName'
              autoComplete='off'
            />

            <label htmlFor='name'>{t('last_name')} </label>
            <Field
              className={errors.lastName ? classes.form_input + ' ' + classes.redBorder : classes.form_input}
              id='lastName'
              type='text'
              name='lastName'
            />

            <label htmlFor='name'>{t('email_address')} * </label>
            <Field
              className={errors.email ? classes.form_input + ' ' + classes.redBorder : classes.form_input}
              id='email'
              type='text'
              name='email'
              validate={validateEmail}
            />

            <label htmlFor='name'>{t('comments')}</label>
            <Field className={classes.form_input} id='comment' type='text' name='comment' />

            <div className={errors.checked ? classes.checkbox + ' ' + classes.redBorder : classes.checkbox}>
              <Field className={classes.check} type='checkbox' name='checked' validate={validateField} />
              {t('agree_rules')} *
              <div onClick={showModalHandler} className={classes.link}>
                {t('read')}
              </div>
            </div>

            <hr />
            <Button text={t('confirm')} />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default DonationForm
