import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classes from './Footer.module.css'

const Footer = (props) => {
  const { t } = useTranslation()

  const siteMap = props.siteMap
  const contacts = [
    {
      img: '/images/icons/marker.png',
      alt: 'Address',
      text: t('contact_address'),
      href: 'https://yandex.ru/maps/org/123975783232/?ll=44.708015%2C40.391897&mode=search&sll=44.635154%2C40.159158&sspn=0.288469%2C0.134506&text=azatazen&z=9',
      newPage: true,
    },
    {
      img: '/images/icons/youtube-icon.png',
      alt: 'Youtube',
      text: t('see_video'),
      href: 'https://youtu.be/PWoBjZWrqJA',
      newPage: true,
    },
    {
      img: '/images/icons/phone.png',
      alt: 'Phone',
      text: '+374 41 542002',
      href: 'tel:+37441542002',
      newPage: true,
    },
    {
      img: '/images/icons/email.png',
      alt: 'Email',
      text: 'info@azatazen.am',
      href: 'mailto:info@azatazen.am',
    },
  ]
  const social = [
    {
      img: '/images/icons/facebook.png',
      alt: 'Facebook',
      href: 'https://www.facebook.com/azatazen',
    },
    {
      img: '/images/icons/instagram.png',
      alt: 'Instagram',
      href: 'https://www.instagram.com/azatazen',
    },
    {
      img: '/images/icons/telegram.png',
      alt: 'Telegram',
      href: 'https://t.me/+WlwI31LF9xI5NzI6',
    },
    {
      img: '/images/icons/youtube.png',
      alt: 'Youtube',
      href: 'https://www.youtube.com/channel/UCkeS5D2HwUFzqZiN5XuyY8A',
    },
  ]
  return (
    <footer className={classes.footer}>
      <div className={classes.logo}>
        <Link to='/'>
          <img src='/images/logo.svg' alt='logo' />
        </Link>
      </div>
      <div className={classes['site-map']}>
        <h5 className={classes.header}>{t('site_map')}</h5>
        {siteMap.map((link) => (
          <div className={classes['footer-row']} key={link.text}>
            <Link to={link.to}>{link.text}</Link>
          </div>
        ))}
      </div>
      <div className={classes.contacts}>
        <h5 className={classes.header}>{t('contacts')}</h5>
        {contacts.map((contact) => (
          <div className={classes['footer-row']} key={contact.img}>
            <div className={classes.icon}>
              <img src={contact.img} alt={contact.alt} />
            </div>
            {contact.newPage ? (
              <a className={classes.decoration} href={contact.href} target='_blank' rel='noreferrer'>
                {contact.text}
              </a>
            ) : (
              <a href={contact.href}>{contact.text}</a>
            )}
          </div>
        ))}
        <div className={classes['footer-row']}>
          {social.map((page) => (
            <a key={page.img} href={page.href} target='_blank' rel='noreferrer'>
              <img src={page.img} alt={page.alt} />
            </a>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default Footer
