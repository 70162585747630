import classes from './Header.module.css'

const Header = ({ text }) => {
  return (
    <div className={classes.header}>
      {/* <span className={classes.green}>ԶԷՆ</span>-ի  */}
      {text}
    </div>
  )
}

export default Header
