import classes from './Portrait.module.css'

const Portrait = (props) => {
  const mainClasses = `${classes.portrait} ${props.clickable ? classes.clickable : ''}`
  const clickHandler = () => {
    if (props.clickable) {
      props.onShowModal(props.id)
    }
  }
  const social = props.social && (
    <div className={classes.social}>
      {props.social.map((page) => {
        return (
          page.href && (
            <a key={page.img} href={page.href} target='_blank' rel='noreferrer'>
              <img src={page.img} alt={page.alt} />
            </a>
          )
        )
      })}
    </div>
  )

  const description = props.description && <div className={classes.description}>{props.description}</div>
  
  return (
    <div className={mainClasses} onClick={clickHandler}>
      {props.image && <img className={classes.img} src={props.image} alt={props.name} />}
      <div className={classes.name}>{props.name}</div>
      <div className={classes.position}>{props.position}</div>
      {description}
      {social}
    </div>
  )
}

export default Portrait
