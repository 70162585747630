import Gallery from '../Gallery/Gallery'

const GallerySection = () => {
  const photosCountToShow = 2
  return (
    <>
      <Gallery count={photosCountToShow} />
    </>
  )
}

export default GallerySection
