import { useTranslation } from 'react-i18next'
import classes from './Donation.module.css'
import Shadow from '../UI/Shadow/Shadow'
import DonationForm from '../DonationForm/DonationForm'
import Modal from '../UI/Modal/Modal'
import { useNavigate } from 'react-router-dom'
import { data } from './data'

const Donation = () => {
  const { t } = useTranslation()

  const params = new URLSearchParams(window.location.search)
  const isSuccess = params.get('its')
  const navigate = useNavigate()

  return (
    <div className={classes.wrapper}>
      {params.has('its') ? (
        <Modal
          styleName='boardMembers'
          onClose={() => {
            navigate('/donation')
          }}
        >
          <div className={classes.modal}>{isSuccess === 'true' ? t('donation_success') : t('donation_fail')}</div>
        </Modal>
      ) : (
        ''
      )}
      <div className={classes.form_col}>
        <DonationForm />

        <div className={classes.rights}>
          <div className={classes.banks_img}>
            <img src='/images/icons/arca.png' alt='Arca' />
            <img src='/images/icons/visa.png' alt='Visa' />
            <img src='/images/icons/masterCard.png' alt='MasterCard' />
          </div>

          <div className={classes.rights_wrap}>
            <img className={classes.rights_img} src='/images/icons/rights.png' alt='Copyright' />
            <p>
              <span>Ազատազէն</span>: {t('rights_reserved')}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.img_col}>
        <Shadow className={classes.pic} src={'/images/photos/donation_boy.png'} alt={'Donation soldier group'} />
        <h4>{t('account_number')}</h4>
        <div className={classes.bank_info_wrap}>
          <p>
            {t('currency')} {t('amd')}
            <br />
            {t('recipient')}
            <br />
            {t('vat_number')} 00243558
            <br />
            {t('bank')}
            <br />
            {t('card')}
          </p>
          <hr />
          <p className={classes.usd_don}>
            {t('currency')} {t('usd')}
            {
                data.map(item => (
                    <>
                        <br />
                        {item}
                    </>
                ))
            }
          </p>
        </div>
        <hr />
        <br />
        <div className={classes.usd_don}>
          <h4>{t('crypto_transfer')}</h4>
          <div className={classes.crypto}>
            <img src='images/icons/bit.png' width="64" alt={t('bitcoin')} />&nbsp;
            <div>
              <div>{t('bitcoin')}</div>
              <div>bc1q66cejgxh5up5zz0mhfqmyk7klg0m8ja8h2jzm2</div>
            </div>
          </div>
          <div className={classes.crypto}>
            <img src='images/icons/eth.png' width="64" alt={t('ethereum')} />&nbsp;
            <div>
              <div>{t('ethereum')}</div>
              <div>0x382Cf49Df99B3280dF0C290e4D1DCd01825f23E4</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Donation
