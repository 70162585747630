import BoardMembers from '../BoardMembers/BoardMembers'

const BoardMembersSection = () => {
  const innerWidth = window.innerWidth

  let membersCountToShow
  if (innerWidth >= 1880) {
    membersCountToShow = 4
  } else if (innerWidth >= 1400) {
    membersCountToShow = 3
  } else {
    membersCountToShow = 2
  }
  return (
    <>
      <BoardMembers count={membersCountToShow} />
    </>
  )
}

export default BoardMembersSection
