import { Fragment } from 'react'
import ReactDOM from 'react-dom'

import classes from './Modal.module.css'

const Backdrop = (props) => {
  return <div className={classes.backdrop} />
}

const ModalOverlay = (props) => {
  return (
    <div className={classes.modal + ' ' + classes[props.styleName]}>
      <div className={classes.close} onClick={props.onClose}></div>
      {props.children}
    </div>
  )
}

const portalElement = document.getElementById('overlays')

const Modal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClose={props.onClose} />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay onClose={props.onClose} styleName={props.styleName}>
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </>
  )
}

export default Modal
