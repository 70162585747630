import React from 'react';
import classes from './RegisterInRegion.module.css';
const imagesContainer = '/images/photos/regions/';
const RegionCard = ({region}) => {
  return (
    <div className={classes.card_wrapper}>
        <div className={classes.card}>
            <img className={classes.region_image} src={imagesContainer + region.image} alt={region.image} />
            <div className={classes.text_container}>
                <a href={region.href} target='_blank' rel='noreferrer'>
                    {region.text}
                </a>
            </div>
        </div>

    </div>
  )
}

export default RegionCard;
