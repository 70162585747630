import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'

import Container from '../UI/Container/Container'
import BoardMemberModal from '../BoardMemberModal/BoardMemberModal'
import Portrait from '../UI/Portrait/Portrait'
import Button from '../UI/Button/Button'

import classes from './BoardMembers.module.css'
import Header from '../UI/Header/Header'

const BoardMembers = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [modalIsShown, setModalIsShown] = useState(false)
  const [selectedMember, setSelectedMember] = useState(false)

  let members = [
    {
        id: 12,
        name: t('naghash_name'),
        position: t('director'),
        image: '/images/photos/members/Naghash-Bagdasaryan.png',
        description: t('naghash_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 1,
        name: t('hrant_name'),
        position: t('board_president'),
        image: '/images/photos/members/Hrant-Manasyan.jpg',
        description: t('hrant_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 2,
        name: t('ishxan_name'),
        position: t('board_member'),
        image: '/images/photos/members/IshxanGevorgyan.jpg',
        description: t('ishxan_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 3,
        name: t('vahagn_name'),
        position: t('board_member'),
        image: '/images/photos/members/Vahagn-Poghosyan.png',
        description: t('vahagn_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 4,
        name: t('arman_p_name'),
        position: t('board_member'),
        image: '/images/photos/members/Arman-Poghosyan.jpg',
        description: t('arman_p_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    }, 
    {
        id: 5,
        name: t('gegham_name'),
        position: t('board_member'),
        image: '/images/photos/members/Gegham-Chandiryan.jpg',
        description: t('gegham_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 6,
        name: t('taron_name'),
        position: t('board_member'),
        image: '/images/photos/members/Taron-Baghdasaryan.png',
        description: t('taron_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 7,
        name: t('andranik_name'),
        position: t('board_member'),
        image: '/images/photos/members/Andranik-Hakobyan.jpg',
        description: t('andranik_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 8,
        name: t('vahe_name'),
        position: t('board_member'),
        image: '/images/photos/members/Vahe-Odabashyan.jpg',
        description: t('vahe_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 9,
        name: t('artur_name'),
        position: t('board_member'),
        image: '/images/photos/members/Arthur-Avdalyan.jpg',
        description: t('artur_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 10,
        name: t('aram_name'),
        position: t('compliance_officer'),
        image: '/images/photos/members/Aram-Kocharyan.jpg',
        description: t('aram_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
    {
        id: 11,
        name: t('arman_a_name'),
        position: t('compliance_officer'),
        image: '/images/photos/members/Arman-Ayvazyan.jpg',
        description: t('arman_a_desc'),
        social: [
          {
            img: '/images/icons/facebook.png',
            alt: 'Facebook',
            href: '',
          },
          {
            img: '/images/icons/linkedin.png',
            alt: 'Linkedin',
            href: '',
          },
        ],
    },
  ]

  if (props.count) {
    members = members.slice(0, props.count)
  }
  const showModalHandler = (memberId) => {
    setSelectedMember(members.find((member) => member.id === memberId))
    setModalIsShown(true)
  }

  const hideModalHandler = () => {
    setModalIsShown(false)
  }

  const onClick = () => {
    navigate('/board-members')
  }

  const helmet = !props.count && (
    <Helmet>
      <title>{t('board_members')}</title>
    </Helmet>
  )
  return (
    <>
      {helmet}
      <Container>
        <div className={classes.wrapper}>
          {modalIsShown && (
            <BoardMemberModal
              onClose={hideModalHandler}
              image={selectedMember.image}
              name={selectedMember.name}
              position={selectedMember.position}
              description={selectedMember.description}
              social={selectedMember.social}
            />
          )}
          <div className={classes.header}>
            <Header text={t('board_members')} />
          </div>
          <div className={classes.portraitGroup}>
            {members.map((member) => (
              <Portrait
                key={member.id}
                id={member.id}
                image={member.image}
                name={member.name}
                position={member.position}
                clickable={!!member.description}
                onShowModal={showModalHandler}
              />
            ))}
          </div>
          {props.count && (
            <div className={classes.button}>
              <Button text={t('more')} onClick={onClick} />
            </div>
          )}
        </div>
      </Container>
    </>
  )
}


export default BoardMembers
