import classes from './Cover.module.css'
import Slider from '../UI/Slider/Slider'

const Cover = () => {
  return (
    <div className={classes.wrapper}>
      <Slider />
    </div>
  )
}

export default Cover
