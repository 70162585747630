import classes from './Shadow.module.css'

const Shadow = (props) => {
  const inheritClasses = `${classes.wrapper} ${props.className ? props.className : ''}`
  const mainClasses = `${inheritClasses} ${props.clickable ? classes.clickable : ''}`

  const clickHandler = () => {
    if (props.clickable) {
      props.onShowModal(props.id)
    }
  }
  return (
    <div className={mainClasses} onClick={clickHandler}>
      <img src={props.src} alt={props.alt} className={props.minus ? classes.shadow_minus : classes.shadow} />
      {props.hasPopUp && props.text && (
        <div className={classes.popUp_withText + ' ' + classes.popUp}>
          <div className={classes.popUp_text}>
            <p>{props.text}</p>
            <p>{props.date}</p>
          </div>
        </div>
      )}

      {props.hasPopUp && !props.text && props.date && (
        <div className={classes.popUp_withDate + ' ' + classes.popUp}>
          <p>{props.date}</p>
        </div>
      )}
    </div>
  )
}
export default Shadow
