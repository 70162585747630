import React, { useState } from 'react'
import classes from './Calendar.module.css'
import { useEffect } from 'react';

const Calendar = () => {
    const [dates, setDates] = useState({});
    useEffect(() => {
        fetch('https://api-v2.azatazen.am/dates')
        .then((response) => response.json())
        .then((data) => {
            setDates(data);
        });
    }, [])

    const Paragraph = ({ type, date, register }) => (
        <p className={classes.paragraph}>
            Հաջորդ <span className={classes.span_text}>{type}</span> քննությունը՝ <span className={classes.span_text}>{`${date.day} ${date.month}ի, ժամը 10:30-ից 12:30, `}</span>
            <a href="https://yandex.com/maps/org/123975783232/?ll=44.865446%2C40.411333&z=9">Ազատազէնի քաղաքացիական հրաձգարանում։ </a>
            {register && (<React.Fragment>Մասնակցության համար պետք է նախօրոք <a href="https://calendly.com/azatazen/meet-with-me?month=2024-05&date=2024-05-02">գրանցվել</a>:</React.Fragment>)}
        </p>
    );

    const {forTheoryDay, forPracticDay} = dates;
  return (
    <div className={classes.main}>
        <div className={classes.main}>
            {forTheoryDay && <Paragraph key={1} type="Տեսական" date={forTheoryDay} register={1} />}
            {forPracticDay && <Paragraph key={2} type="Գործնական" date={forPracticDay} />}
        </div>
    </div>
  )
}

export default Calendar
