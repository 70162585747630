export const data = [
    'Beneficiary Name: AZATAZEN NGO',
    'Beneficiary Address: 134/1 Tsarav Aghbyur St. Yerevan 0052, Armenia',
    'Beneficiary Account: 2052822220791020',
    'Beneficiary\'s bank\'s name: INECOBANK CJSC',
    'Beneficiary\'s bank\'s Address: YEREVAN, TUMANYAN STR. 17, ARMENIA',
    'Beneficiary\'s bank\'s SWIFT code: INJSAM22',
    'Intermediary bank\'s name: BANK OF GEORGIA',
    'Intermediary bank\'s SWIFT code: BAGAGE22',
    'Intermediary bank\'s USD correspondent: CITI BANK, NEW YORK, UNITED STATES',
    'Intermediary bank\'s USD correspondent SWIFT code: CITIUS33',
    'Details or Purpose: Donation',
    'Card number: 4578910001000607',
    '',
    'If your transfer system doesn\'t support the fields for correspondent bank details, you can make a transfer using the below-mentioned details:',
    'Intermediary bank\'s name: CITI BANK, NEW YORK, UNITED STATES',
    'Intermediary bank\'s SWIFT code: CITIUS33',
    'Beneficiary\'s bank\'s name: JSC BANK OF GEORGIA',
    'Beneficiary\'s bank\'s Address: 0160 TBILISI, GEORGIA',
    'Beneficiary\'s bank\'s SWIFT code: BAGAGE22',
    'Beneficiary Name: INECOBANK CJSC',
    'Beneficiary Address: YEREVAN, TUMANYAN STR. 17, ARMENIA',
    'Beneficiary Account: GE88BG0000000817111900',
    'Details or Purpose: AZATAZEN NGO,  2052822220791020,  134/1 Tsarav Aghbyur St. Yerevan 0052, Armenia'
]