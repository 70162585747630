import Modal from '../UI/Modal/Modal'
import classes from './DonationRuleModal.module.css'
import { useTranslation } from 'react-i18next'

const DonationRuleModal = (props) => {
  const { t } = useTranslation()
  return (
    <Modal onClose={props.onClose} styleName='donation'>
      <div className={classes.wrapper}>
        <div>{t('donation_rule1')}</div>
        <div>{t('donation_rule2')}</div>
        <div>{t('donation_rule3')}</div>
        <div>{t('donation_rule4')}</div>
        <div>{t('donation_rule5')}</div>
        <div>{t('donation_rule6')}</div>
      </div>
    </Modal>
  )
}

export default DonationRuleModal
