import React, { useState } from 'react'
import classes from './DetailedInventory.module.css'
import { Link, useParams } from 'react-router-dom'
import nested_data from '../nested_table'
import { useTranslation } from 'react-i18next'

const DetailedInventory = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [modalIsShown, setModalIsSnown] = useState(false)
  const [clickedItem, setClickedItem] = useState()
  const data = nested_data.find((item) => item.id === id)

  const showModal = (index) => {
    if (!modalIsShown) {
      setModalIsSnown(true)
      setClickedItem(index)
    } else if (modalIsShown && index !== clickedItem) {
      setClickedItem(index)
      setModalIsSnown(true)
    } else if (modalIsShown && index === clickedItem) {
      setModalIsSnown(false)
    }
  }

  return (
    <div className={classes.wrapper}>
      <Link to='/inventory' className={classes.link}>
        <img src='/images/icons/arrow_left.png' alt='arrow back' />
        {t('back_inventory')}
      </Link>
      <div className={classes.wrapper_table}>
        <section className={classes.table_name}>
          <p className={classes.small}>№</p>
          <p className={classes.big}>{t('name')}</p>
        </section>
        {data.items.map((item, index) => {
          return (
            <div className={classes.table_items}>
              <section className={clickedItem === index && modalIsShown ? classes.highlightedItem : classes.item}>
                <p className={classes.small}>{item.number}</p>
                <section className={classes.big}>
                  <p className={classes.name}>{t(item.name)}</p>
                  {item.additional_info && (
                    <img
                      src='/images/icons/arrow_down.png'
                      alt='arrow down'
                      className={modalIsShown && clickedItem === index ? classes.arrow_up : classes.arrow_down}
                      onClick={() => showModal(index)}
                    />
                  )}
                </section>
              </section>

              {clickedItem === index && (
                <section className={modalIsShown ? classes.dropdown_menu : classes.close}>
                  <section className={classes.small + ' ' + classes.fake}>11</section>
                  <section className={classes.big}>
                    <p className={classes.name}>{clickedItem === index && t(item.additional_info)}</p>
                  </section>
                </section>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DetailedInventory
