import React from 'react'
import classes from './PurposeSection.module.css'
import { useTranslation } from 'react-i18next'
import Header from '../UI/Header/Header'
import Shadow from '../UI/Shadow/Shadow'

const PurposeSection = () => {
  const { t } = useTranslation()
  return (
    <div className={classes.wrapper}>
      <div className={classes.imgWrapper}>
        <Shadow src='/images/photos/purpose.png' minus />
      </div>
      <div className={classes.text}>
        <Header text={t('purpose')} />
        <div className={classes.description}>
          {t('purpose_text')}
          <div>{t('promote')}</div>
          <ul className={classes.list}>
            <li>{t('purpose1')}</li>
            <li>{t('purpose2')}</li>
            <li>{t('purpose3')}</li>
          </ul>
          {t('organize')}
          <ul className={classes.list}>
            <li>{t('purpose4')}</li>
            <li>{t('purpose5')}</li>
            <li>{t('purpose6')}</li>
            <li>{t('purpose7')}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PurposeSection
