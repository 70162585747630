import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Home from './Components/Home/Home'
import Footer from './Components/Footer/Footer'
import Documents from './Components/Documents/Documents'
import Navbar from './Components/Navbar/Navbar'
import Donation from './Components/Donation/Donation'
import BoardMembers from './Components/BoardMembers/BoardMembers'
import Gallery from './Components/Gallery/Gallery'
import ScrollToTop from './Components/ScrollToTop'
import Inventory from './Components/Inventory/Inventory'
import DetailedInventory from './Components/Inventory/DetailedInventory/DetailedInventory'
import BecomeMember from './Components/BecomeMember/BecomeMember'
import StickyCTAButton from './Components/CTA/cta'
import Calendar from './Components/Calendar/Calendar'
import RegisterInRegion from './Components/RegisterInRegion/RegisterInRegion'

function App() {
  const { t } = useTranslation()

  const siteMap = [
    {
      to: '/',
      text: t('about_us'),
    },
    {
      to: '/inventory',
      text: t('inventory'),
    },
    {
      to: '/gallery',
      text: t('gallery'),
    },
    {
      to: '/documents',
      text: t('documents'),
    },
    {
      to: '/board-members',
      text: t('board_members'),
    },
    {
      to: '/become-member',
      text: t('become_member'),
      isInFooter: true,
    },
    {
      to: '/donation',
      text: t('support_us'),
      isInFooter: true,
    },
  ]
  return (
    <div className='App'>
      <BrowserRouter>
        <ScrollToTop>
          <Navbar siteMap={siteMap} />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='donation' element={<Donation />} />
            <Route path='mission' element={<Documents />} />
            <Route path='inventory' element={<Inventory />} />
            <Route path='gallery' element={<Gallery />} />
            <Route path='documents' element={<Documents />} />
            <Route path='register-in-region' element={<RegisterInRegion />} />
            <Route path='calendar' element={<Calendar />} />
            <Route path='board-members' element={<BoardMembers />} />
            <Route path='become-member' element={<BecomeMember />} />
            <Route path='inventory/:id' element={<DetailedInventory />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer siteMap={siteMap} />
          <StickyCTAButton />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  )
}

export default App
