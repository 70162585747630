import React from 'react';
import Container from '../UI/Container/Container';
import classes from './RegisterInRegion.module.css';
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next';
import RegionCard from './RegionCard';

const RegisterInRegion = () => {
    const { t } = useTranslation();
    const regions = [
        {
            text: t('goris'),
            href: 'https://bit.ly/3HXJ4Wo',
            image: 'goris.jpg'
        },
    ];

    return (
        <>
            <Helmet>
                <title>{t('register_in_region')}</title>
            </Helmet>
            <>
                <div className={classes.wrapper}>
                    {regions.map((region) => (
                        <RegionCard key={region.href} region={region} />
                    ))}
                </div>
            </>
        </>
    );
}

export default RegisterInRegion;
