import Modal from '../UI/Modal/Modal'
import Portrait from '../UI/Portrait/Portrait'

const BoardMemberModal = (props) => {
  return (
    <Modal onClose={props.onClose} styleName='boardMembers'>
      <Portrait
        clickable={false}
        image={props.image}
        name={props.name}
        position={props.position}
        description={props.description}
        social={props.social}
      />
    </Modal>
  )
}

export default BoardMemberModal
