import classes from './Button.module.css'

const Button = ({ text, onClick, type }) => {
  return (
    <button type={type ?? 'submit'} className={classes.btn} onClick={onClick}>
      {text}
    </button>
  )
}

export default Button
