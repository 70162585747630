const nested_data = [
  // MEDICINE
  {
    id: 'medicine1',
    items: [
      {
        name: 'Tourniquet',
        number: 1,
      },
      {
        name: 'Chest',
        number: 2,
      },

      {
        name: 'needle',
        number: 3,
      },
      {
        name: 'naso',
        number: 4,
      },
      {
        name: 'Flat',
        number: 5,
      },

      {
        name: 'Combat',
        number: 6,
      },
      {
        name: 'Gauze',
        number: 7,
      },
      {
        name: 'Sheers',
        number: 8,
      },
      {
        name: 'NAR',
        number: 9,
      },
      {
        name: 'Gloves',
        number: 10,
      },
      {
        name: 'Blanket',
        number: 11,
      },

      {
        name: 'Marker',
        number: 12,
      },
      {
        name: 'TCCC',
        number: 13,
        additional_info: 'TCCC_add',
      },
    ],
  },

  {
    id: 'medicine2',
    items: [
      {
        name: 'Soap',
        number: 1,
      },
      {
        name: 'Toothbrush',
        number: 2,
      },
      {
        name: 'Kit',
        number: 3,
      },
      {
        name: 'wipes',
        number: 4,
      },
      {
        name: 'mirror',
        number: 5,
      },
      {
        name: 'Powder',
        number: 6,
      },
      {
        name: 'Vaseline',
        number: 7,
      },
    ],
  },

  {
    id: 'medicine3',
    items: [
      {
        name: 'enterol',
        number: 1,
        additional_info: 'enterol_add',
      },
      {
        name: 'arcoxia',
        number: 2,
        additional_info: 'arcoxia_add',
      },

      {
        name: 'antibiotic',
        number: 3,
      },
      {
        name: 'antipyretic',
        number: 4,
      },
      {
        name: 'antimosquitos',
        number: 5,
      },
      {
        name: 'betadin',
        number: 7,
      },
    ],
  },

  //   FOOD
  {
    id: 'food1',
    items: [
      {
        name: 'bar',
        number: 1,
      },
      {
        name: 'brickets',
        number: 2,
      },

      {
        name: 'MRE',
        number: 3,
      },
      {
        name: 'veg',
        number: 4,
      },
      {
        name: 'twix',
        number: 5,
      },
      {
        name: 'tea',
        number: 6,
      },
    ],
  },

  {
    id: 'food2',
    items: [
      {
        name: 'hydration',
        number: 1,
      },
      {
        name: 'filtration',
        number: 2,
      },

      {
        name: 'purification',
        number: 3,
      },
    ],
  },
  {
    id: 'food3',
    items: [
      {
        name: 'fork',
        number: 1,
      },
      {
        name: 'glass',
        number: 2,
      },

      {
        name: 'propane',
        number: 3,
      },
      {
        name: 'alcohol',
        number: 4,
      },
      {
        name: 'firelight',
        number: 4,
      },
    ],
  },

  //   WEATHER
  {
    id: 'weather1',
    items: [
      {
        name: 'socks',
        number: 1,
      },
      {
        name: 'underware',
        number: 2,
      },

      {
        name: 'shirts',
        number: 3,
      },
    ],
  },

  {
    id: 'weather2',
    items: [
      {
        name: 'underwear',
        number: 1,
        additional_info: 'underwear_add',
      },
      {
        name: 'jacket',
        number: 2,
      },

      {
        name: 'gloves',
        number: 3,
        additional_info: 'gloves_add',
      },
      {
        name: 'fleece',
        number: 4,
      },
      {
        name: 'hand',
        number: 5,
      },

      {
        name: 'Merino',
        number: 6,
      },
      {
        name: 'Softshell',
        number: 7,
      },
    ],
  },

  {
    id: 'weather3',
    items: [
      {
        name: 'membrane',
        number: 1,
      },
      {
        name: 'poncho',
        number: 2,
      },

      {
        name: 'cover',
        number: 3,
      },
    ],
  },
  {
    id: 'weather4',
    items: [
      {
        name: 'glows',
        number: 1,
      },
      {
        name: 'Ballistic',
        number: 2,
      },

      {
        name: 'balaclava',
        number: 3,
      },
      {
        name: 'plates',
        number: 4,
      },
      {
        name: 'helmets',
        number: 5,
      },
    ],
  },

  {
    id: 'weather5',
    items: [
      {
        name: 'Sleeping',
        number: 1,
      },
      {
        name: 'bivy',
        number: 2,
      },
      {
        name: 'mattress',
        number: 3,
      },
      {
        name: 'slides',
        number: 4,
      },
      {
        name: 'shorts',
        number: 5,
      },
      {
        name: 'pillow',
        number: 6,
      },
    ],
  },
  //   MISSION
  {
    id: 'mission1',
    items: [
      {
        name: 'Weapon ',
        number: 1,
      },
      {
        name: 'magazines',
        number: 2,
      },

      {
        name: 'sling',
        number: 3,
      },
      {
        name: 'kit',
        number: 4,
      },
      {
        name: 'weaponAcs',
        number: 5,
      },

      {
        name: 'grenades',
        number: 6,
      },
      {
        name: 'mines',
        number: 7,
      },
      {
        name: 'night',
        number: 8,
      },
    ],
  },

  {
    id: 'mission2',
    items: [
      {
        name: 'Map',
        number: 1,
      },
      {
        name: 'Compass',
        number: 2,
      },

      {
        name: 'markers',
        number: 3,
      },
      {
        name: 'ruler',
        number: 4,
      },
      {
        name: 'curvimeter',
        number: 5,
      },

      {
        name: 'protractor',
        number: 6,
      },
      {
        name: 'ranger',
        number: 7,
      },
      {
        name: 'Binocle',
        number: 8,
      },
    ],
  },

  {
    id: 'mission3',
    items: [
      {
        name: 'Powerbank',
        number: 1,
      },
      {
        name: 'battery',
        number: 2,
      },
      {
        name: 'Headlight',
        number: 3,
        additional_info: 'Headlight_add',
      },
      {
        name: 'cyalume',
        number: 4,
      },

      {
        name: 'rockets',
        number: 5,
      },
    ],
  },
  {
    id: 'mission4',
    items: [
      {
        name: 'multi',
        number: 1,
      },
      {
        name: 'insulating',
        number: 2,
      },
      {
        name: 'tire',
        number: 3,
      },
      {
        name: 'safety',
        number: 4,
      },
      {
        name: 'thread',
        number: 5,
      },
      {
        name: 'Condom',
        number: 6,
      },
      {
        name: 'fishing',
        number: 7,
      },
      {
        name: 'waterproof',
        number: 8,
      },

      {
        name: 'rubber',
        number: 9,
      },
      {
        name: 'nylon',
        number: 10,
      },
      {
        name: 'paracord',
        number: 11,
      },
      {
        name: 'Camouflage',
        number: 12,
      },
      {
        name: 'shovel',
        number: 13,
      },
    ],
  },

  {
    id: 'mission5',
    items: [
      {
        name: 'uniform',
        number: 1,
      },
      {
        name: 'boots',
        number: 2,
      },
      {
        name: 'carrier',
        number: 3,
      },
      {
        name: 'Backpack',
        number: 4,
      },
    ],
  },
  {
    id: 'mission6',
    items: [
      {
        name: 'Radio',
        number: 1,
      },
      {
        name: 'Antenna',
        number: 2,
      },
      {
        name: 'Spare',
        number: 3,
      },
    ],
  },
]

export default nested_data
