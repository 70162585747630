import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Field, Form } from 'formik'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import Shadow from '../UI/Shadow/Shadow'
import Button from '../UI/Button/Button'
import classes from './BecomeMember.module.css'
import classesBtn from '../UI/Button/Button.module.css'
import Modal from '../UI/Modal/Modal'

const BecomeMember = () => {
  const { t } = useTranslation()
  const [responseMessage, setResponseMessage] = useState({text: '', pdfFile: null});
  const [sendingState, setSendingState] = useState('');

  const validateEmail = (value) => {
    let error
    if (!value) {
      error = t('required')
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = t('invalid')
    }
    return error
  }

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    birthdate: '',
    aboutBecomingMember: '',
    checked: false,
    amount_familiar: false,
    image: null,
  };

  const validateField = (value) => {
    let error
    if (!value) {
      error = t('required')
    }
    return error
  }

  const branch = [
    {value: "", text: ""},
    {value: "yerevan", text: `${t('yerevan')}`},
    {value: "vayq", text: `${t('vayq')}`},
    {value: "voskepar", text: `${t('voskepar')}`},
    {value: "vanadzor", text: `${t('vanadzor')}`}
  ];


  const onInvalid = useCallback((e, fieldCheck) => {
    const { validity } = e.target;

    if (validity.valueMissing) {
        if (fieldCheck === 1) {
            e.target.setCustomValidity(t('input_fields_rights_err'));
        } else if (fieldCheck === 2) {
            e.target.setCustomValidity(t('amount_membership'));
        } else {
            e.target.setCustomValidity(t('input_fields_empty_err_msg'));
        }
    } else if (validity.patternMismatch) {
        e.target.setCustomValidity(t('input_fields_format_err'));
    } else {
        e.target.setCustomValidity("");
    }
  }, [t]);

  const getFieldClass = useCallback((error) => {
    if (error) {
      return classes.form_input + ' ' + classes.redBorder
    }
    return classes.form_input
  }, []);

  const handleImageChange = useCallback((e, setFieldValue) => {
    const fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 5) {
      alert('File size exceeds 5 MiB');
      e.target.value = null;
      setFieldValue('image', null);
    } else {
      setFieldValue('image', e.target.files[0]);
    }
  }, []);

  const onButtonClick = (file) => {
    fetch('https://api-v2.azatazen.am/download-pdf/' + file)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'azatazen-file.pdf'); // Set the desired file name
        document.body.appendChild(link);
        const newWindow = window.open(url, '_blank');
        if (!newWindow) {
          console.error('Unable to open new window or tab. Please allow pop-ups.');
        }
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading PDF:', error);
      })
  }

  return (
    <>
      <Helmet>
        <title>{t('application_for_membership')}</title>
      </Helmet>
    <div className={classes.wrapper}>
    {responseMessage.text ? (
        <Modal
          styleName='boardMembers'
          onClose={() => {
            setResponseMessage({text: '', pdfFile: null});
          }}
        >
          <div className={classes.modal}>{responseMessage.text}
          {
           responseMessage.pdfFile &&
           <button className={classesBtn.btn + ' ' + classes.button } onClick={() => onButtonClick(responseMessage.pdfFile)}>{t('download_button')}</button>
          }
          </div>
        </Modal>
      ) : (
        ''
      )}
      <div className={classes.form_col}>
      <div className={classes.form_column}>
        <h2>{t('application_for_membership')}</h2>
        <div className={classes.subject}>
          {t('membership_subtitle')}
          <Link to='/documents'> {t('charter')}</Link>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            //values.branch = branch.find(item=>item.value === values.branch)?.text ?? '';

            setSendingState('sending');
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
              formData.append(key, values[key]);
            });
            const requestOptions = {
              method: 'POST',
              body: formData,
            }
            fetch('https://api-v2.azatazen.am/become-member', requestOptions)
              .then((response) => response.json())
              .then((data) => {
                if (data.errorMessage) {
                  setResponseMessage({pdfFile: null, text: data.errorMessage});
                } else {
                  setResponseMessage({pdfFile: data.pdfFile, text: 'Ձեր դիմումը ընդունված է', });
                }
                setSendingState('sent');
                resetForm({initialValues});
              }).catch((error) => {
                setResponseMessage({pdfFile: null, text: 'Տեղի ունեցավ սխալ'});
                setSendingState('sent');
              });
          }}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ errors, values, validateForm, setFieldValue }) => (
            <Form className={classes.form} autoComplete='off'>
              <div>
                <label htmlFor="name">{t('first_name') + ', ' + t('last_name') + ', ' + t('middle_name') }</label>
                <Field type="text" id="name" name="name" required className={getFieldClass(errors.name)}  onInvalid={e => onInvalid(e)} onInput={e => e.target.setCustomValidity("")} />
              </div>

              <div>
                <label htmlFor="phone">{t('phone_number')}</label>
                <Field type="tel" id="phone" name="phone" pattern="[0-9]+" required className={getFieldClass(errors.phone)} onInvalid={e => onInvalid(e)} onInput={e => e.target.setCustomValidity("")} />
              </div>

              <div>
                <label htmlFor="email">{t('email_address')}</label>
                <Field type="text" id="email" name="email" required className={getFieldClass(errors.email)} validate={validateEmail} onInvalid={e => onInvalid(e)} onInput={e => e.target.setCustomValidity("")} />
              </div>

              <div>
                <label htmlFor="birthdate">{t('birthday')}</label>
                <Field type="date" id="birthdate" name="birthdate" required className={getFieldClass(errors.birthdate)} />
              </div>

              <div>
                <label htmlFor="aboutBecomingMember">{t('about_becoming_member')}</label><br />
                <Field name="aboutBecomingMember" className={getFieldClass(errors.aboutBecomingMember)} validate={validateField} />
              </div>

              <div>
                {/* <label>{t('aggree_with_charter')}</label> */}
                <div className={classes.checkbox}>
                  <Field className={classes.check} type='checkbox' name='checked' required validate={validateField} onInvalid={e => onInvalid(e, 1)} onInput={e => e.target.setCustomValidity("")} />
                  {t('aggree_with_charter')}
                </div>
              </div>

              <div>
                {/* <label>{t('familiar_with_amount')}</label> */}
                <div className={classes.checkbox}>
                  <Field className={classes.check} type='checkbox' name='amount_familiar' required validate={validateField} onInvalid={e => onInvalid(e, 2)} onInput={e => e.target.setCustomValidity("")} />
                  {t('familiar_with_amount')}
                </div>
              </div>
              <hr />
              <div>
                <label htmlFor="image">
                  {t('attach_image')}<span className={classes.span}> {t('attach_image_bold')}</span>
                </label>
                <div className={getFieldClass(errors.image) + ' ' + classes.radio}>
                  <input type="file" id="image" name="image" accept="image/*" onChange={(event) => {
                    handleImageChange(event, setFieldValue);
                  }} />
                </div>
              </div>
              <hr />
              {sendingState !== 'sending' ? (
                <Button text={t('submit')} />
              ) : (
                t('sending')
              )}
            </Form>
          )}
        </Formik>
      </div>
      <div className={classes.rights}>
      </div>
    </div>
    <div className={classes.img_col}>
      <div className={classes.form_column} >
        <h2>{t('membership_rights_responsibilities')}</h2>
      </div>
      <div className={classes.subject}>
      {t('become_member_subject')}
      </div>
      <p className={classes.headers}>{t('become_member_header1')}</p>
      <ol>
        <li>{t('become_member_rule1')}</li>
        <li>{t('become_member_rule2')}</li>
        <li>{t('become_member_rule3')}</li>
        <li>{t('become_member_rule4')}</li>
        <li>{t('become_member_rule5')}</li>
      </ol>
      <p className={classes.headers}>{t('become_member_header2')}</p>
      <ol>
        <li>{t('become_member_rule6')}</li>
        <li>{t('become_member_rule7')}</li>
        <li>{t('become_member_rule8')}</li>
        <li>{t('become_member_rule9')}</li>
      </ol>
      <p className={classes.headers}>{t('become_member_header3')}</p>
      <ol>
        <li>{t('become_member_rule10')}</li>
        <li>{t('become_member_rule11')} <a target="_blank" rel="noreferrer" href="https://youtu.be/pCWDDZAp7aM">{t('find_here')}</a></li>
      </ol>
      <Shadow className={classes.pic} src={'/images/photos/donation_boy.png'} alt={'Donation soldier group'} />
    </div>
  </div>
  </>
  )
}

export default BecomeMember
