import Cover from '../Cover/Cover'
import GallerySection from '../GallerySection/GallerySection'
import BoardMembersSection from '../BoardMembersSection/BoardMembersSection'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import PurposeSection from '../PurposeSection/PurposeSection'

const Home = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Helmet>
        <title>{t('azatazen')}</title>
      </Helmet>
      <Cover />
      <PurposeSection />
      <GallerySection />
      <BoardMembersSection />
    </div>
  )
}

export default Home
