import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classes from './Navbar.module.css'
import Button from '../UI/Button/Button'
import DropdownLng from '../UI/DropdownLng/DropdownLng'

const URLS = [
    {
        name: 'calendar',
        url: '/calendar',
    },
    {
        name: 'register',
        url: 'https://calendly.com/azatazen/meet-with-me',
    },
    {
        name: 'register_in_region',
        url: 'register-in-region'
    },
    {
        name: 'exam_questions',
        url: 'https://drive.google.com/file/d/1BvbG--ORjZWiGU0CKMptprdCG8HmN1zV/view?usp=drive_link',
    },
    {
        name: 'practical_exam_procedure',
        url: '/exam_procedure_website.pdf',
    },
    {
        name: 'mock_exam',
        url: 'http://exam.azatazen.am/exam',
    },
    {
        name: 'weapon_acquisition_proc',
        url: '/weapon_acquisition_proc.pdf',
    }
];


const Navbar = ({ siteMap }) => {
  const { t } = useTranslation()
  const [isOpen, setisOpen] = useState(true)

  const iconClickHandler = () => {
    setisOpen(!isOpen)
  }

  const setLinkClass = ({ isActive }) => {
    return `${classes.link} ${isActive ? classes.active : classes.notActive}`
  }


  return (
    <div className={isOpen ? classes.wrapper : classes.wrapper + ' ' + classes.open}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <NavLink to='/' className={({ isActive }) => (isActive ? classes.active : classes.notActive)}>
            <img src='/images/logo.svg' alt='logo' />
          </NavLink>
        </div>
        <div className={classes.menu}>
          {siteMap.map((link) =>
            !link.isInFooter ? (
              <div key={link.text} onClick={() => setisOpen(true)} className={classes.menu_item}>
                <NavLink className={setLinkClass} to={link.to}>
                  {link.text}
                </NavLink>
              </div>
            ) : null
          )}
          <div className={classes.dropdown}>
            <button className={classes.dropbtn}>{t('exam')}</button>
            <div className={classes.dropdown_content}>
                {
                    URLS.map(({name, url}) => {
                        return (
                            <a key={url} className={classes.link+' '+ classes.exemQuations} href={url} target={"_blank"}  rel="noopener noreferrer">
                                {t(name)}
                                <img className={classes.arrow} src='/images/icons/external-link.png' alt='logo' />
                            </a>
                        );
                    })
                }
            </div>
          </div>

          <div className={classes.right}>
            <div className={classes.languages}>
              <a href='tel: +37441542002'>
                <img src='/images/icons/phone.png' className={classes.phone} alt='phone' />
              </a>
              <DropdownLng />
            </div>
            <Link to='become-member' onClick={() => setisOpen(true)} className={classes.becomeMember}>
              <Button text={t('become_member')} />
            </Link>
            <Link to='donation' onClick={() => setisOpen(true)} className={classes.btn}>
              <Button text={t('donation')} />
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.burgerIcon} onClick={iconClickHandler}>
        <img src='/images/icons/burgerMenu.png' alt='icon' />
      </div>
      <div className={classes.mobileLogo}>
        <NavLink to='/' className={({ isActive }) => (isActive ? classes.active : classes.notActive)}>
          <img src='/images/logo.svg' alt='logo' />
        </NavLink>
      </div>
    </div>
  )
}

export default Navbar
