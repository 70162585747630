import React, { useState } from 'react'
import classes from './Slider.module.css'

const Slider = () => {
  const dataSlider = [
    {
      id: 1,
      src: '/images/photos/heroPic.jpg',
    },
    {
      id: 2,
      src: '/images/photos/heroPic2.png',
    },
    // {
    //   id: 3,
    //   src: '/images/photos/heroPic3.png',
    // },
  ]
  const [slideIndex, setSlideIndex] = useState(1)
  const showPrev = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1)
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length)
    }
  }
  const showNext = () => {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1)
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1)
    }
  }

  return (
    <div className={classes.container}>
      {dataSlider.map((image) => {
        return (
          <div
            className={slideIndex === image.id ? classes.slide + ' ' + classes.active : classes.slide}
            key={image.id}
          >
            <img src={image.src} alt={image.src} />
          </div>
        )
      })}
      <img src='/images/icons/arrowleft.png' alt='left' className={classes.left} onClick={showPrev} />
      <img src='/images/icons/arrowleft.png' alt='right' className={classes.right} onClick={showNext} />
    </div>
  )
}

export default Slider
