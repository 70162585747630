import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useState } from 'react'
import classes from './DropdownLng.module.css'

const DropdownLng = () => {
  const { i18n } = useTranslation()
  let languages = [
    {
      lang: 'hy',
      img: '/images/icons/arm.png',
    },
    {
      lang: 'ru',
      img: '/images/icons/ru.png',
    },
    {
      lang: 'en',
      img: '/images/icons/en.png',
    },
  ]
  const ref = useRef()

  const [isOpen, setIsOpen] = useState(false)
  let src
  switch (i18n.language) {
    case 'hy':
      src = '/images/icons/arm.png'
      break
    case 'ru':
      src = '/images/icons/ru.png'
      break
    case 'en':
      src = '/images/icons/en.png'
      break
    default:
      break
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen])

  return (
    <div className={classes.wrapper} ref={ref}>
      <div
        className={classes.container}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <img src={src} alt='arm flag' className={isOpen ? classes.hidden : null} />
        <div className={isOpen ? classes.arrowUp : classes.arrowDown}>
          <img src='/images/icons/arrowDown.png' alt='arrow' />
        </div>
      </div>

      <div className={isOpen ? classes.dropdownBox : classes.hidden + ' ' + classes.dropdownBox}>
        {languages.map((lang) => (
          <img
            key={lang.lang}
            src={lang.img}
            alt='flag'
            onClick={() => {
              i18n.changeLanguage(lang.lang)
              setIsOpen(false)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default DropdownLng
